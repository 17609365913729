import React, { useState, useLayoutEffect } from "react"
import { navigate } from "gatsby"
import AuthContext from "./auth-context"
import { logout, isLoggedIn, userLogin, userRegister } from "./auth-functions"

const AuthProvider = ({ children }) => {
  const [loggedInState, setLoggedInState] = useState(null)

  // Much better login checker!
  const checkLoggedInState = () => {
    setLoggedInState(isLoggedIn())
  }

  // Just a "register" wrapper. This should actually also perform login
  const performRegister = (
    values,
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {}
  ) => {
    const callback = () => {
      performLogin(values.email, values.password, setLoading, setApiError, afterCallback)
    }
    return userRegister(
      values,
      setLoading,
      setApiError,
      callback
    )
  }

  // Just a "userLogin" wrapper that also updates the global state
  const performLogin = (
    email,
    password,
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {}
  ) => {
    const callback = async () => {
      setLoggedInState(true)
      await afterCallback()
    }
    return userLogin(email, password, setLoading, setApiError, callback)
  }

  // Perform a logout, update the global logged in state and navigate away
  const performLogout = (navigateLink = null) => {
    logout()
    setLoggedInState(false)
    if (navigateLink !== null) {
      navigate(navigateLink)
    }
  }

  // on every render... maybe too much?
  useLayoutEffect(checkLoggedInState)

  return (
    <AuthContext.Provider
      value={{
        loggedInState,
        checkLoggedInState,
        performRegister,
        performLogin,
        performLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
