import React from "react"
import { QueryParamProvider } from "use-query-params"

import AuthProvider from "./src/context/auth/auth-provider"
import CheckoutProvider from "./src/context/checkout/checkout-provider"
import CartProvider from "./src/context/cart/cart-provider"
// import ProductProvider from "./src/context/product/product-provider";
// import AreaCalcProvider from "./src/context/area-calc/area-calc-provider";

const RootElementWrapper = ({ element }) => {
  return (
    <QueryParamProvider>
      <AuthProvider>
        <CheckoutProvider>
          <CartProvider>{element}</CartProvider>
        </CheckoutProvider>
      </AuthProvider>
    </QueryParamProvider>
  )
}

export const wrapRootElement = RootElementWrapper
