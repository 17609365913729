import { kyWrapped } from "../lib/utils"

// **** Login with user email and password
export const login = (email, password) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/jwt-auth/v1/token`
  return kyWrapped(url, {
    json: {
      username: email,
      password,
    },
    credentials: "include",
    throwHttpErrors: false,
    method: "post",
  })
}

// **** Register user
export const register = (values) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/register`
  return kyWrapped(url, {
    json: {
      ...values,
    },
    credentials: "include",
    method: "post",
  })
}

// **** Request to reset password
export const requestPassword = (email) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/password/reset`
  return kyWrapped(url, {
    json: { user_login: email },
    method: "post",
  })
}

// **** Update password with email token
export const submitPassword = (password, token) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/password/update`
  return kyWrapped(url, {
    json: {
      token,
      password,
    },
    method: "post",
  })
}