import { kyWrapped } from "../lib/utils";

// **** Post promo coupon
export const postPromoCoupon = (coupon, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/coupon`;
  const config = {
    json: {
      coupon,
      coupon_method: "add",
    },
    credentials: "include",
    method: "post",
    logout,
  };
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return kyWrapped(url, config);
};

// **** Remove promo coupon
export const deletePromoCoupon = (coupon, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/coupon`;
  const config = {
    json: {
      coupon,
      coupon_method: "remove",
    },
    credentials: "include",
    method: "post",
    logout,
  };
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return kyWrapped(url, config);
};
