exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-delivery-jsx": () => import("./../../../src/pages/checkout/delivery.jsx" /* webpackChunkName: "component---src-pages-checkout-delivery-jsx" */),
  "component---src-pages-checkout-index-jsx": () => import("./../../../src/pages/checkout/index.jsx" /* webpackChunkName: "component---src-pages-checkout-index-jsx" */),
  "component---src-pages-checkout-payment-jsx": () => import("./../../../src/pages/checkout/payment.jsx" /* webpackChunkName: "component---src-pages-checkout-payment-jsx" */),
  "component---src-pages-checkout-summary-jsx": () => import("./../../../src/pages/checkout/summary.jsx" /* webpackChunkName: "component---src-pages-checkout-summary-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-intro-jsx": () => import("./../../../src/pages/intro.jsx" /* webpackChunkName: "component---src-pages-intro-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-my-account-billing-details-jsx": () => import("./../../../src/pages/my-account/billing-details.jsx" /* webpackChunkName: "component---src-pages-my-account-billing-details-jsx" */),
  "component---src-pages-my-account-index-jsx": () => import("./../../../src/pages/my-account/index.jsx" /* webpackChunkName: "component---src-pages-my-account-index-jsx" */),
  "component---src-pages-my-account-order-history-jsx": () => import("./../../../src/pages/my-account/order-history.jsx" /* webpackChunkName: "component---src-pages-my-account-order-history-jsx" */),
  "component---src-pages-my-account-shipping-details-jsx": () => import("./../../../src/pages/my-account/shipping-details.jsx" /* webpackChunkName: "component---src-pages-my-account-shipping-details-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-wishlist-jsx": () => import("./../../../src/pages/wishlist.jsx" /* webpackChunkName: "component---src-pages-wishlist-jsx" */),
  "component---src-templates-pages-blog-category-page-jsx": () => import("./../../../src/templates/pages/blog-category-page.jsx" /* webpackChunkName: "component---src-templates-pages-blog-category-page-jsx" */),
  "component---src-templates-pages-blog-page-jsx": () => import("./../../../src/templates/pages/blog-page.jsx" /* webpackChunkName: "component---src-templates-pages-blog-page-jsx" */),
  "component---src-templates-pages-branch-page-jsx": () => import("./../../../src/templates/pages/branch-page.jsx" /* webpackChunkName: "component---src-templates-pages-branch-page-jsx" */),
  "component---src-templates-pages-category-page-jsx": () => import("./../../../src/templates/pages/category-page.jsx" /* webpackChunkName: "component---src-templates-pages-category-page-jsx" */),
  "component---src-templates-pages-product-page-jsx": () => import("./../../../src/templates/pages/product-page.jsx" /* webpackChunkName: "component---src-templates-pages-product-page-jsx" */)
}

