const isBrowser = () => typeof window !== "undefined"

// FACEBOOK PIXEL --- Select Product Event
export const pixelSelect = (item) => {
  // Prepare data
  const payload = {
    content_name: item?.title,
    content_category: item?.categories?.map((cat) => cat.title)?.join("/"),
    value: item?.price,
    currency: "ZAR",
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event ViewProduct [trackCustom]: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "ViewProduct", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Select Product Event".'
      )
  }
}

// FACEBOOK PIXEL --- Add Items To Cart Event
export const pixelAddToCart = (item) => {
  // Prepare data
  const payload = {
    content_name: item?.title,
    value: item?.price,
    currency: "ZAR",
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event AddToCart: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "AddToCart", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Add Items To Cart Event".'
      )
  }
}

// FACEBOOK PIXEL --- Update Cart Event
export const pixelUpdateCart = (item, quantity) => {
  // Prepare data
  const payload = {
    content_name: item?.product_name,
    value: item?.price_each,
    quanity: quantity,
    currency: "ZAR",
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event UpdateCart [trackCustom]: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "UpdateCart", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Update Cart Event".'
      )
  }
}

// FACEBOOK PIXEL --- Remove From Cart Event
export const pixelRemoveFromCart = (item) => {
  // Prepare data
  const payload = {
    content_name: item?.product_name,
    value: item?.price_each,
    currency: "ZAR",
    quanity: item?.quantity
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event RemoveFromCart [trackCustom]: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "RemoveFromCart", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Remove From Cart Event".'
      )
  }
}

// FACEBOOK PIXEL --- Begin Checkout
export const pixelBeginCheckout = (data) => {
  // Prepare data
  const payload = {
    num_items: data.reduce(
      (tot, curr) => (curr?.bundled_by ? tot : tot + curr.quantity),
      0
    ),
    value: data.reduce(
      (tot, curr) => (curr?.bundled_by ? tot : tot + curr.line_total),
      0
    ),
    currency: "ZAR",
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event InitiateCheckout: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "InitiateCheckout", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Begin Checkout".'
      )
  }
}

// FACEBOOK PIXEL --- Purchase Event
export const pixelPurchase = (paymentData) => {
  // Prepare data
  const payload = {
    value: paymentData.total,
    currency: "ZAR",
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event Purchase: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "Purchase", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Purchase Event".'
      )
  }
}

// FACEBOOK PIXEL --- Complete Registration event
export const pixelCompleteRegistration = () => {
  // Prepare data
  const payload = {
    currency: "ZAR",
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event CompleteRegistration: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "CompleteRegistration", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Complete Registration Event".'
      )
  }
}

// FACEBOOK PIXEL --- Login Event
export const pixelLogin = () => {
  // Console log the event that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event Login [trackCustom]: no payload")

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "Login")
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Login Event".'
      )
  }
}

// FACEBOOK PIXEL --- Logout Event
export const pixelLogout = () => {
  // Console log the event that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event Logout [trackCustom]: no payload")

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "Logout")
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Logout Event".'
      )
  }
}

// FACEBOOK PIXEL --- Update Account Event
export const pixelUpdateAccount = () => {
  // Console log the event that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event UpdateAccount [trackCustom]: no payload")

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "UpdateAccount")
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Update Account Event".'
      )
  }
}

// FACEBOOK PIXEL --- Contact Us event
export const pixelContactUs = () => {
  // Console log the event that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event Contact: no payload")

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "Contact")
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Contact Us Event".'
      )
  }
}

// FACEBOOK PIXEL --- Click event
export const pixelClick = (text) => {
  // Prepare data
  const payload = {
    clickText: text,
  }

  // Console log data that will be sent
  process.env.GATSBY_PIXEL_DEBUG == "true" &&
    console.log("pixel event ClickEvent: payload = ", payload)

  // Send data
  try {
    if (isBrowser() && window.fbq != null) {
      window.fbq("trackCustom", "ClickEvent", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Click Event".'
      )
  }
}
