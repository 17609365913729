const isBrowser = () => typeof window !== "undefined"

// GOOGLE ANALYTICS --- Select Product Event
export const analyticsSelect = (item) => {
  // Prepare data
  const itemStruct = {
    item_id: item?.database_id,
    item_name: item?.title,
    price: item?.price,
    currency: "ZAR",
    item_category: item?.categories?.map((cat) => cat.title)?.join("/"),
  }
  const eventParams = {
    event_category: "Product",
    event_label: "Select Product",
    content_type: "product",
    currency: "ZAR",
    items: [itemStruct],
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: select_item -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "select_item", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch (e) {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Select Product Event".',
        e
      )
  }
}

// GOOGLE ANALYTICS --- Add Items To Cart Event
export const analyticsAddToCart = (item, quantity) => {
  // Prepare data
  const itemsStruct = {
    item_id: item?.database_id,
    item_name: item?.title,
    price: item?.price,
    currency: "ZAR",
    quantity: quantity,
    item_category: item?.categories?.map((cat) => cat.title)?.join("/"),
  }
  const eventParams = {
    event_category: "Cart",
    event_label: "Add To Cart",
    content_type: "product",
    currency: "ZAR",
    items: [itemsStruct],
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: add_to_cart -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "add_to_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Add Items To Cart Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Remove Items From Cart Event
export const analyticsRemoveFromCart = (item) => {
  // Prepare data
  const itemsStruct = [
    {
      item_id: item?.product_id,
      item_name: item?.product_name,
      price: item?.price_each,
      currency: "ZAR",
      quanity: item?.quantity,
    },
  ]
  const eventParams = {
    event_category: "Cart",
    event_label: "Remove From Cart",
    content_type: "product",
    currency: "ZAR",
    items: itemsStruct,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: remove_from_cart -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "remove_from_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Remove Items From Cart Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Update Items In Cart Event
export const analyticsUpdateCart = (item, quantity) => {
  // Prepare data
  const itemsStruct = [
    {
      item_id: item?.product_id,
      item_name: item?.product_name,
      price: item?.price_each,
      currency: "ZAR",
      quanity: quantity,
    },
  ]
  const eventParams = {
    event_category: "Cart",
    event_label: "Update Item In Cart",
    content_type: "product",
    currency: "ZAR",
    items: itemsStruct,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: update_cart [custom] -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "update_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Update Items In Cart Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Add Item To Wishlist Event
export const analyticsAddToWishlist = (productId) => {
  // Prepare data
  const itemsStruct = [
    {
      item_id: productId,
      currency: "ZAR",
    },
  ]
  const eventParams = {
    event_category: "Wishlist",
    event_label: "Add To Wishlist",
    content_type: "product",
    currency: "ZAR",
    items: itemsStruct,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: add_to_wishlist -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "add_to_wishlist", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Add Item To Wishlist Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Remove Item To Wishlist Event
export const analyticsRemoveFromWishlist = (productId) => {
  // Prepare data
  const itemsStruct = [
    {
      item_id: productId,
      currency: "ZAR",
    },
  ]
  const eventParams = {
    event_category: "Wishlist",
    event_label: "Remove From Wishlist",
    content_type: "product",
    currency: "ZAR",
    items: itemsStruct,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: remove_from_wishlist -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "remove_from_wishlist", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function Remove Item From Wishlist Event".'
      )
  }
}

// GOOGLE ANALYTICS --- View Cart
export const analyticsViewCart = (data) => {
  // Prepare data
  const itemsStruct = data
    .filter((it) => {
      return !it?.bundled_by
    })
    .map((it) => {
      return {
        item_id: it.tracking_sku || it.sku,
        item_name: it.name || it.product_name,
        quantity: it.quantity,
        price: it.unit_price,
        currency: "ZAR",
        item_category: it.categories.map((cat) => cat.name).join("/"),
      }
    })
  const eventParams = {
    event_category: "Cart",
    event_label: "Viewed Cart",
    currency: "ZAR",
    items: itemsStruct,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: view_cart [custom] -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "view_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "View Cart".'
      )
  }
}

// GOOGLE ANALYTICS --- Begin checkout
export const analyticsBeginCheckout = (data) => {
  // Prepare data
  const itemsStruct = data
    .filter((it) => {
      return !it?.bundled_by
    })
    .map((it) => ({
      item_id: it.tracking_sku || it.sku,
      item_name: it.name || it.product_name,
      quantity: it.quantity,
      price: it.unit_price,
      currency: "ZAR",
      item_category: it.categories.map((cat) => cat.name).join("/"),
    }))
  const eventParams = {
    event_category: "Checkout",
    event_label: "Begin Checkout",
    content_type: "product",
    items: itemsStruct,
    currency: "ZAR",
    checkout_option: "Begin Checkout",
    checkout_step: 1,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: begin_checkout -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "begin_checkout", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Go To Cart".'
      )
  }
}

// GOOGLE ANALYTICS --- Checkout Step - Delivery information
export const analyticsCheckoutDelivery = (data) => {
  // Prepare data
  const itemsStruct = data
    .filter((it) => {
      return !it?.bundled_by
    })
    .map((it) => ({
      item_id: it.tracking_sku || it.sku,
      item_name: it.name || it.product_name,
      quantity: it.quantity,
      price: it.unit_price,
      currency: "ZAR",
      item_category: it.categories.map((cat) => cat.name).join("/"),
    }))
  const eventParams = {
    event_category: "Checkout",
    event_label: "Provide Delivery Information",
    content_type: "product",
    items: itemsStruct,
    currency: "ZAR",
    checkout_option: "Provide Delivery Information",
    checkout_step: 2,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: checkout_progress 2 (Provide Delivery Information) -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "checkout_progress", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Checkout Step = Delivery Information".'
      )
  }
}

// GOOGLE ANALYTICS --- Checkout Step - Payment
export const analyticsCheckoutViewPaymentOptions = (data) => {
  // Prepare data
  const itemsStruct = data.map((it) => ({
    item_id: it.tracking_sku || it.sku,
    item_name: it.name || it.product_name,
    quantity: it.quantity,
    price: it.unit_price,
    currency: "ZAR",
    item_category: it.categories.map((cat) => cat.name).join("/"),
  }))
  const eventParams = {
    event_category: "Checkout",
    event_label: "View Payment Methods",
    content_type: "product",
    items: itemsStruct,
    currency: "ZAR",
    checkout_option: "View Payment Methods",
    checkout_step: 3,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: checkout_progress 3 (View Payment Methods) -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "checkout_progress", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Checkout Step - Payment".'
      )
  }
}

// GOOGLE ANALYTICS --- Payment method credit card
export const analyticsSelectPaymentMethod = (paymentOption) => {
  // Prepare data
  const eventParams = {
    event_category: "Checkout",
    event_label: "Select Payment Option - " + paymentOption,
    checkout_step: 3,
    currency: "ZAR",
    checkout_option: paymentOption,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: set_checkout_option 3 (Select Payment Option) -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "set_checkout_option", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Method Credit Card".'
      )
  }
}

// GOOGLE ANALYTICS --- Payment method credit card
export const analyticsPayNowOnOldOrder = (orderNumber) => {
  // Prepare data
  const eventParams = {
    event_category: "Checkout",
    event_label: "Repay Existing Order",
    checkout_step: 3,
    currency: "ZAR",
    checkout_option: "pay_old_order",
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: set_checkout_option 3 (Old Order: Pay Now) -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "set_checkout_option", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Method Credit Card".'
      )
  }
}

// GOOGLE ANALYTICS --- Checkout Step - Delivery information
export const analyticsPlaceOrder = (data) => {
  // Prepare data
  const itemsStruct = data
    .filter((it) => {
      return !it?.bundled_by
    })
    .map((it) => ({
      item_id: it.tracking_sku || it.sku,
      item_name: it.name || it.product_name,
      quantity: it.quantity,
      price: it.unit_price,
      currency: "ZAR",
      item_category: it.categories.map((cat) => cat.name).join("/"),
    }))
  const eventParams = {
    event_category: "Checkout",
    event_label: "Place Order",
    content_type: "product",
    items: itemsStruct,
    currency: "ZAR",
    checkout_option: "Place Order",
    checkout_step: 4,
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: checkout_progress 4 (Place Order) -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "checkout_progress", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Checkout Step = Delivery Information".'
      )
  }
}

// GOOGLE ANALYTICS --- Purchase Event
export const analyticsPurchase = (paymentData) => {
  // Prepare "purchase" data
  const itemsStruct = paymentData.line_items.map((item, i) => ({
    item_id: item.tracking_sku || item.sku,
    item_name: item.name || item.product_name,
    quantity: item.quantity,
    price: item.unit_price,
    currency: "ZAR",
    item_category: item.categories.map((cat) => cat.name).join("/"),
  }))
  const eventParams_purchase = {
    event_category: "Checkout",
    event_label: "Complete Purchase",
    transaction_id: paymentData.id,
    affiliation: process.env.GATSBY_WEBSITE_TITLE,
    value: paymentData.total,
    currency: "ZAR",
    items: itemsStruct,
    currency: "ZAR",
  }

  // Console log "purchase" data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: purchase -> eventParams = ", eventParams_purchase)

  // Prepare "conversion" data
  const eventParams_conversion = {
    send_to: `${process.env.GATSBY_GTAG_ACCOUNT_ID}/aPoVCOKWs4YBEISDpaID`,
    value: paymentData.total,
    currency: "ZAR",
    transaction_id: paymentData.id,
  }

  // Console log "conversion" data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: conversion -> eventParams = ",
      eventParams_conversion
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "purchase", eventParams_purchase)
      window.gtag("event", "conversion", eventParams_conversion)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Purchase Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Register Event
export const analyticsRegister = () => {
  // Prepare data
  const eventParams = {
    event_category: "Account",
    event_label: "Register",
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: sign_up -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "sign_up", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Register Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Login Event
export const analyticsLogin = () => {
  // Prepare data
  const eventParams = {
    event_category: "Account",
    event_label: "Login",
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: login -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "login")
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Login Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Logout Event
export const analyticsLogout = () => {
  // Prepare data
  const eventParams = {
    event_category: "Account",
    event_label: "Logout",
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log("gtag event: logout [custom] -> eventParams = ", eventParams)

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "logout", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Logout Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Update Account Event
export const analyticsUpdateAccount = () => {
  // Prepare data
  const eventParams = {
    event_category: "Account",
    event_label: "Update Account Information",
  }

  // Console log data that will be sent
  process.env.GATSBY_GTAG_DEBUG == "true" &&
    console.log(
      "gtag event: update_account [custom] -> eventParams = ",
      eventParams
    )

  // Send data
  try {
    if (isBrowser() && window.gtag) {
      window.gtag("event", "update_account")
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Update Account Event".'
      )
  }
}
