import { login, register } from "../../api/auth"
import { pixelLogin, pixelLogout } from "../../services/facebook-pixel"
import { analyticsLogin, analyticsLogout } from "../../services/google-analytics"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem("wcUser")) {
    return JSON.parse(window.localStorage.getItem("wcUser"))
  }
  return {}
}

export const userRegister = (
  values,
  setLoading = () => { },
  setApiError = () => { },
  callback = () => { }
) => {
  setLoading(true)

  register(values)
    .then((responseData) => {
      if (responseData.data && responseData.data.status >= 400) {
        let errorMessage = "Something went wrong while trying to register"
        console.log("errorMessage: ", responseData.data)
        setApiError({ header: "Registration invalid", content: errorMessage })
      } else {
        setApiError(false)
      }
      callback()
    })
    .catch(async (error) => {
      console.log("Exception while registering: ", error)
      if (error.message) {
        setApiError(error.message)
      } else if (error.content) {
        setApiError(error.content)
      } else {
        console.log("Error Response: ", error)
        setApiError("Registration failed")
      }
      setLoading(false)
    })
}

export const userLogin = (
  email,
  password,
  setLoading = () => { },
  setApiError = () => { },
  callback = () => { },
  credentialCheckOnly = false
) => {
  setLoading(true)
  login(email, password)
    .then(async (responseData) => {
      // 4xx responses do not throw an exception. We force it here so we can handle them as errors
      if (responseData.data && responseData.data.status >= 400) {
        throw responseData
      } else {
        if (!credentialCheckOnly) {
          setUser(responseData)
          analyticsLogin()
          pixelLogin()
        }
        setApiError(false)
        await callback(responseData)
        setLoading(false)
      }
    })
    .catch(async (error) => {
      console.log("Exception while logging in: ", error)
      let errorString = "Login failed, something went wrong"

      if (error?.code?.includes("incorrect_password")) {
        errorString = `Incorrect password. If you're having trouble logging in, you can reset your password`
      } else if (error?.code?.includes("invalid_email")) {
        errorString =
          "Invalid email, no account exists with this email address. If you do not have an account, you can register a new one"
      } else if (error?.message) {
        errorString = error.message
      } else if (error?.content) {
        errorString = error.content
      }

      // Remove this HTML if it's in the error message
      errorString = errorString.replace("<strong>ERROR</strong>: ", "")
      setApiError(errorString)
      setLoading(false)
    })
}

export const setUser = (user) => {
  if (user.token && user.token.includes(".")) {
    // Decode the JWT webtoken from base64
    const tokenPayload = JSON.parse(atob(user.token.split(".")[1]))
    const localStorageUser = { ...user, tokenPayload }
    if (isBrowser())
      window.localStorage.setItem("wcUser", JSON.stringify(localStorageUser))
  } else {
    console.log("Login token format invalid: ", user)
    // eslint-disable-next-line no-throw-literal
    throw "Login token format invalid"
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  let expires = null

  // Check if our token is still valid
  if (isBrowser() && user.token && user.tokenPayload) {
    expires = user.tokenPayload.exp
  }

  if (!expires) {
    return false
  }

  if (expires < Math.floor(new Date() / 1000)) {
    logout()
    return false
  }

  return !!user.token
}

export const logout = () => {
  if (isBrowser()) {
    window.localStorage.setItem("wcUser", JSON.stringify({}))
    analyticsLogout()
    pixelLogout()
  }
}
