/* eslint-disable no-use-before-define */
import React, { useState, useLayoutEffect, useContext, useEffect } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import CartContext from "./cart-context"
import AuthContext from "../auth/auth-context"
import { getUser } from "../auth/auth-functions"
import {
  postWishlistItemRequest,
  deleteWishlistItemRequest,
} from "../../api/wishlist"
import { getOrder } from "../../api/account"
import {
  postCartItemRequest,
  getCartRequest,
  deleteCartItemRequest,
  updateCartItemRequest,
  clearCartRequest,
  selectShippingMethodRequest,
} from "../../api/cart"
import { postPromoCoupon, deletePromoCoupon } from "../../api/coupon"
import { windowWidths } from "../../theme/theme-config"
import CheckoutContext from "../checkout/checkout-context"
import {
  analyticsRemoveFromCart,
  analyticsUpdateCart,
} from "../../services/google-analytics"
import {
  pixelRemoveFromCart,
  pixelUpdateCart,
} from "../../services/facebook-pixel"

const CartProvider = ({ children }) => {
  const [cartHash, setCartHash] = useState(null)
  const [cartCount, setCartCount] = useState(null)
  const [wishlistContents, setWishlistContents] = useState(null)
  const [cartContents, setCartContents] = useState(null)
  const [cartMeta, setCartMeta] = useState(null)
  const [cartShipping, setCartShipping] = useState(null)
  const [cartReady, setCartReady] = useState(true)
  const [cartState, setCartState] = useState("cart")
  const { performLogout, checkLoggedInState } = useContext(AuthContext)
  const {
    setAvailableProvinces,
    setAvailableBranches,
    selectedProvince,
    setSelectedProvince,
    availableProvinces,
  } = useContext(CheckoutContext)

  // Prepare graphql data
  const branchesData = useStaticQuery(CART_QUERY)
  const branches = branchesData?.seaSiteInfo?.site_branches || []

  // Only open cart with openCartDrawer from other files
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false)
  const openCartDrawer = (newCartDrawerOpenState, fromUrl = null) => {
    if (typeof window != "undefined") {
      if (window?.screen?.width < windowWidths.desktopSmall) {
        if (!fromUrl) {
          fromUrl =
            typeof window == "undefined" ? "/" : window.location.pathname
        }
        navigate("/cart/", {
          state: {
            replace: true,
            fromUrl,
          },
        })
      } else {
        if (window?.location?.pathname == "/cart/") {
          newCartDrawerOpenState = false
        }
        setCartDrawerOpen(newCartDrawerOpenState)
      }
    }
  }

  // Only open wishlist drawer with openWishlistDrawer from other files
  const [wishlistDrawerOpen, setWishlistDrawerOpen] = useState(false)
  const openWishlistDrawer = (newWishlistDrawerOpenState) => {
    if (typeof window != "undefined") {
      if (window?.screen?.width <= windowWidths.desktopSmall) {
        navigate("/wishlist/", {
          state: {
            fromUrl:
              typeof window == "undefined" ? "/" : window.location.pathname,
          },
        })
      } else {
        if (window?.location?.pathname == "/wishlist/") {
          newWishlistDrawerOpenState = false
        }
        setWishlistDrawerOpen(newWishlistDrawerOpenState)
      }
    }
  }

  // Select a shipping method
  const selectShippingMethod = async (selected_shipping_method) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    // Select a shipping method. The backend then returns the full cart object
    return selectShippingMethodRequest(selected_shipping_method, user.token)
      .then((selectShippingMethodResponse) => {
        setCart(selectShippingMethodResponse)
      })
      .catch((selectShippingMethodError) => {
        getCart()
        throw selectShippingMethodError
      })
  }

  // Add product ID to wishlist
  const addToWishlist = async (productId) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    // Add product to wishlist. The backend then returns the full cart object
    return postWishlistItemRequest(productId, user.token)
      .then((addWishlistResponse) => {
        setCart(addWishlistResponse)
        return addWishlistResponse
      })
      .catch((addWishlistError) => {
        getCart()
        throw addWishlistError
      })
  }

  // Remove product ID from wishlist
  const removeFromWishlist = async (productId) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    // Delete product from wishlist. The backend then returns the full cart object
    return deleteWishlistItemRequest(productId, user.token)
      .then((deleteWishlistResponse) => {
        setCart(deleteWishlistResponse)
        return deleteWishlistResponse
      })
      .catch((deleteWishlistError) => {
        getCart()
        throw deleteWishlistError
      })
  }

  // Clear current cart
  const clearCart = async () => {
    setCartReady(false)
    const user = getUser()
    const { token } = user
    const clearCartResponse = await clearCartRequest(token)
    setCartReady(true)
  }

  // Add product to Cart. Struct expects a product_id and optional quantity
  const addToCart = async (postCartItemStruct, skipUpdate = false) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    console.log("Preparing to add to cart...", postCartItemStruct)

    // #### Debug messages ####
    const productMessage =
      postCartItemStruct.id && `product ${postCartItemStruct.id}`
    console.log(
      `Adding to cart: ${postCartItemStruct.quantity || 1}x ${productMessage}`
    )
    // #### Debug messages ####

    return postCartItemRequest(postCartItemStruct, user.token, performLogout)
      .then(async (postCartItemResponse) => {
        console.log(
          "DEBUG: postCartItemResponse RESPONSE - ",
          postCartItemResponse
        )
        if (skipUpdate) {
          setCartReady(true)
        } else {
          // Update cart structures
          setCart(postCartItemResponse)
        }
        // If action failed. Throw message
        if (postCartItemResponse.action_result.result == "fail") {
          let errorString = "Failed to add product to cart"
          if (postCartItemResponse.action_result.message) {
            errorString = postCartItemResponse.action_result.message
            console.log("Failed to add product to cart: ", errorString)
          }
          throw postCartItemResponse.action_result.message
        }
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  // Remove product from cart
  const removeFromCart = async (product) => {
    checkLoggedInState()
    analyticsRemoveFromCart(product)
    pixelRemoveFromCart(product)
    setCartReady(false)
    const user = getUser()
    return deleteCartItemRequest(product, user.token, performLogout)
      .then(async (removeFromCartResponse) => {
        setCart(removeFromCartResponse)
        return removeFromCartResponse
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  // Add coupon to Cart
  const addCoupon = async (coupon) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    return postPromoCoupon(coupon, user.token, performLogout)
      .then(async (postCouponResponse) => {
        // Update cart structures
        setCart(postCouponResponse)

        // If action failed. Throw message
        if (postCouponResponse.action_result.result == "fail") {
          let errorString = "Failed to add coupon to cart"
          if (postCouponResponse.action_result.message) {
            errorString = postCouponResponse.action_result.message
            console.log("Failed to add coupon to cart: ", errorString)
          }
          throw postCouponResponse.action_result.message
        }
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  // Remove coupon from Cart
  const removeCoupon = async (coupon) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    return deletePromoCoupon(coupon, user.token, performLogout)
      .then(async (removeCouponResponse) => {
        // Update cart structures
        setCart(removeCouponResponse)

        // If action failed. Throw message
        if (removeCouponResponse.action_result.result == "fail") {
          let errorString = "Failed to remove coupon from cart"
          if (removeCouponResponse.action_result.message) {
            errorString = removeCouponResponse.action_result.message
            console.log("Failed to remove coupon from cart: ", errorString)
          }
          throw removeCouponResponse.action_result.message
        }
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  // Update product in cart
  const updateInCart = async (product, quantity) => {
    checkLoggedInState()
    analyticsUpdateCart(product, quantity)
    pixelUpdateCart(product, quantity)
    setCartReady(false)
    const user = getUser()
    return updateCartItemRequest(
      { ...product, quantity },
      user.token,
      performLogout
    )
      .then(async (updateInCartResponse) => {
        setCart(updateInCartResponse)
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  // Get all cart data
  const getCart = async () => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()
    return getCartRequest(user, performLogout) // Get items in cart
      .then((getCartResponse) => {
        setCart(getCartResponse)
      })
      .catch((e) => {
        setCart({})
        setCartReady(true)
        throw e
      })
  }

  // Fetch order by Order ID and handle errors properly
  const getOrderById = async (orderId) => {
    const { token } = getUser()

    // Verify the order ID is valid
    try {
      const orderData = await getOrder(orderId, token, performLogout)

      // Setting order data will render the PeachPay component which will trigger the fetch of payment data
      if (orderData.id) {
        return orderData
      } else {
        return {
          error: "An error occured while fetching your order, order invalid.",
        }
      }
    } catch (e) {
      console.log("Failed to get order by ID")
      if (e && e.errors && e.errors[0] && e.errors[0].detail) {
        console.log(e.errors[0].detail)
        return { error: e.errors[0].detail }
      } else if (e.message) {
        return { error: e.message }
      } else {
        return {
          error: "An error occured while fetching your order.",
        }
      }
    }
  }

  // On first load, get the cart
  useLayoutEffect(() => {
    getCart()
  }, [])

  // Update our cart including contents, meta and shipping
  const setCart = (response) => {
    // Check that cart response looks valid
    if (response?.action_result?.result != "success") {
      console.log("setCart ERROR: Invalid cart response")
      return
    }

    // Only update if the cart hash changed
    const newCartHash = didCartHashChange(response, cartHash)

    // Cart hash has changed, update cart and hash
    if (newCartHash) {
      setCartHash(newCartHash)
      setCartContents(
        response.cart_contents.sort((a, b) => b.price_total - a.price_total)
      )
      // Update cart-count for icon
      updateCartCount(
        response.cart_contents.reduce((tot, num) => tot + num.quantity, 0)
      )
    }

    // Update other states received
    setCartMeta(response.cart_meta)
    setCartShipping(response.cart_shipping)
    setWishlistContents(response.wishlist_contents)

    // Done updating!
    setCartReady(true)
  }

  // Update cart count if it changed
  const updateCartCount = (newCartCount) => {
    if (newCartCount !== cartCount) {
      // When we have orderid specified in the URL, don't mess with cart states
      if (
        typeof window != "undefined" &&
        !window.location.search.includes("orderid")
      ) {
        setCartState("cart")
      }
      setCartCount(newCartCount)
    }
  }

  // Fetch address from WC
  useEffect(() => {
    branches.forEach((branch) => {
      setAvailableProvinces((prevState) => {
        if (prevState.includes(branch.address.province)) {
          return prevState
        } else {
          return [...prevState, branch.address.province]
        }
      })
    })
  }, [])

  useEffect(() => {
    setSelectedProvince(availableProvinces[0])
  }, [availableProvinces])

  useEffect(() => {
    setAvailableBranches(
      branches.filter((branch) => selectedProvince === branch.address.province)
    )
  }, [selectedProvince, availableProvinces])

  return (
    <CartContext.Provider
      value={{
        cartCount,
        cartState,
        setCartState,
        getCart,
        clearCart,
        addToCart,
        updateInCart,
        removeFromCart,
        addToWishlist,
        removeFromWishlist,
        addCoupon,
        removeCoupon,
        selectShippingMethod,
        wishlistContents,
        branches,
        cartContents,
        cartMeta,
        cartShipping,
        cartReady,
        setCartReady,
        cartDrawerOpen,
        openCartDrawer,
        wishlistDrawerOpen,
        openWishlistDrawer,
        getOrderById,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider

// This function tries to check if the user is on cart page
// It helps us to reduce numerous cart-related calls when they are not necessary
const isOnCheckoutPage = () => {
  if (
    typeof window !== "undefined" &&
    window.location.pathname &&
    !window.location.pathname.includes("/checkout/")
  ) {
    return false
  }

  return true
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

// Compare a cart's hash to one stored in the state
const didCartHashChange = (cart, oldHash) => {
  let newCartHash = -1
  if (cart?.cart_meta?.cart_hash) {
    newCartHash = cart.cart_meta.cart_hash
  } else if (cart && cart?.cart_contents?.length === 0) {
    newCartHash = 1 // Set to 1 for an empty cart
  }

  if (newCartHash !== -1 && newCartHash !== oldHash) {
    return newCartHash
  }
  return false
}

// ===============
//      QUERY
// ===============
export const CART_QUERY = graphql`
  {
    seaSiteInfo {
      site_branches {
        address {
          address_link
          google_maps_address
          street_address
          province
        }
        contact_details {
          contact_email
          name
          telephones {
            telephone_label
            telephone_number
          }
        }
        shipping_coordinates {
          appear_on_contact_us
          does_deliveries
          does_collections
          latitude
          longitude
        }
      }
    }
  }
`
