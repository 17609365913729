import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import Helmet from "react-helmet"

import "typeface-roboto"
import "typeface-poppins"

export const windowWidths = {
  desktopHD: 1920,
  desktopSmall: 1240,
  desktopStandard: 1366,
  tabletLarge: 1024,
  tabletSmall: 768,
  mobileLarge: 480,
  mobileStandard: 375,
}

const ThemeConfig = ({ children }) => {
  const brandQueryData = useStaticQuery(BRAND_QUERY)
  const brandColors = brandQueryData?.seaSiteInfo?.site_brand || {}

  const colors = {
    // Global brand colours
    brandPrimary: brandColors?.primary_color || "#7fac29",
    brandPrimaryLight: brandColors?.primary_color_light || "#92b830",
    brandHover: brandColors?.hover_color || "#34a21f",
    brandSecondary: brandColors?.secondary_color || "#fff3dd",
    brandGrey: brandColors?.grey_color || "#f5f5f5",

    // Generic colours
    white: "#ffffff",
    offwhite: "#929292",
    charcoal: "#36454f",
    black: "#111",
    black1: "#4A4A4C",
    black2: "#2b2b3a",
    black3: "#121212",
    black4: "#1b1b1b",
    blue: "#003A8D",
    lightblue: "#5e83b7",
    greyblue: "#5279b1",
    blue2: "#194d98",
    blue3: "#2A449A",
    darkgrey2: "#03111f",
    darkgrey: "#333333",
    grey: "#707070",
    grey2: "#818181",
    lightgrey5: "#9a9a9a",
    lightgrey4: "#c0c0c0",
    lightgrey3: "#DDDDDD",
    lightgrey2: "#F2F2F2",
    lightgrey1: "#F6F6F6",
    yellow: "#FFD800",
    darkyellow: "#FFCB00",
    red: "#FF0000",
    // display message colors
    success: {
      fontColor: "#fff",
      light: "#12B000",
      dark: "#15C05F",
    },
    info: {
      fontColor: "#fff",
      light: "#1FB6FF",
      dark: "#1CABF1",
    },
    warning: {
      fontColor: "rgba(0,0,0,0.7)",
      light: "#FFC82C",
      dark: "#F3BE26",
    },
    danger: {
      fontColor: "#fff",
      light: "#FF4949",
      dark: "#EF3C3C",
    },
  }

  const theme = {
    boxShadow: "0px 1px 2px #00000029",
    maxWidth: `${windowWidths.desktopSmall}px`,
    breakLarge: `${windowWidths.desktopSmall}px`,
    breakMedium: `${windowWidths.tabletLarge}px`,
    breakSmall: `${windowWidths.tabletSmall}px`,
    breakTiny: `${windowWidths.mobileLarge}px`,
    breakXTiny: `${windowWidths.mobileStandard}px`,
    primaryFont: "Poppins, Roboto, Arial, sans-serif",
    secondaryFont: "Poppins, Roboto, Cambria, serif",
    headingColor: colors.black4,
    bodyFont: "Poppins, Roboto, Arial, sans-serif",
    bodyColor: colors.black1,
    colors,
  }

  const GlobalStyle = createGlobalStyle`
    html {
      box-sizing: border-box;
      font-size: 1em;
    }
  
    *, *:before, *:after {
      box-sizing: inherit;
      font-family: ${theme.bodyFont};
    }
  
    body {
      padding: 0;
      margin: 0;
      color: ${theme.bodyColor};
      font-family: ${theme.bodyFont};
      font-size: 1rem;
      font-weight: 400;
      line-height: 2;
      font-style: normal;  
      -webkit-overflow-scrolling: touch;  
    }
  
    body.menu-fixed,
    body.drawer-fixed {
      overflow: hidden;
      width:100%;
      position: fixed;
    }
  
    div {
      color: ${theme.headingColor};
    }
  
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
      font-family: ${theme.primaryFont};
      font-weight: bold;
      font-style: normal;
      color: inherit;
      text-transform: uppercase;
      &.no-transform {
        text-transform: none;
      }
      &.centered {
        text-align: center;
      }
      &.light {
        font-weight: normal;
      }
      &.heavy {
        font-weight: 900;
      }
    }
  
    h1 {
      font-size: 3.2rem;
      line-height: 4.7rem;
      
      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        font-size: 2.4rem;
        line-height: 3.1rem;
      }
    }
  
    h2 {
      font-size: 2.4rem;
      line-height: 3.1rem;
      
      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        font-size: 1.75rem;
        line-height: 2.6rem;
      }
    }
  
    h3 {
      font-size: 1.75rem;
      line-height: 2.6rem;
      
      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }
  
    h4 {
      font-size: 1.3rem;
      line-height: 2rem;
      
      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  
    h5 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    h6 {
      font-size: 0.975rem;
      line-height: 1.3rem;
    }
  
    p {
      font-family: ${theme.primaryFont};
      font-size: 1rem;
      line-height: 1.9rem;
      font-weight: normal;
      font-style: normal;
      &.large {
        font-size: 1.3rem;
        line-height: 2.2rem;
      
        @media (max-width: ${({ theme }) => theme.breakTiny}) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  
    a {
      font-family: ${theme.primaryFont};
      text-decoration: none;
      color: inherit;
    }
  
    img {
      max-width: 100%;
      height:auto;
    }
  
    table {
      border: 1px solid ${theme.colors.painswick};
      border-collapse: collapse;
    }
  
    table td {
      border: 1px solid ${theme.colors.painswick};
      padding: 20px 30px;
    }
  
    table tr:nth-child(odd) {
      background: ${theme.colors.cloud};
    }
  
    .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 400ms ease-in-out;
      z-index: 1;
    }
  
    .ReactModal__Overlay--after-open {
      opacity: 1;
    }
  
    .ReactModal__Overlay--before-close {
      opacity: 0;
    }
  
    .ReactModal__Content{
      width: 600px;
      @media (max-width: ${theme.breakSmall}) {
        width: 350px;
      }
    }
    
    button, a.button-class{
      display: block;
      -webkit-appearance: none;
      line-height: 1.2rem;
      border: 1px solid ${theme.colors.brandPrimary};
      background-color: ${theme.colors.brandPrimary};
      border-radius: 50px;
      outline: none;
      color: ${theme.colors.white};
      text-align: center;
      padding: 1rem 3rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover, &.button-active {
        border: 1px solid ${theme.colors.brandPrimary};
        background-color: ${theme.colors.brandHover};
        color: white;
        transition: all 0.2s ease-in-out;
      }
      @media (max-width: ${theme.breakMedium}) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      align-items: center;
    } 
  `
  return (
    <ThemeProvider theme={theme}>
      <Helmet key="createGlobalStyle" />
      <GlobalStyle />
      <>{children}</>
    </ThemeProvider>
  )
}

export default ThemeConfig

// ===============
//      QUERY
// ===============

const BRAND_QUERY = graphql`
  {
    seaSiteInfo {
      site_brand {
        primary_color
        primary_color_light
        secondary_color
        hover_color
        grey_color
      }
    }
  }
`
